exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-index-js": () => import("./../../../src/pages/about/index.js" /* webpackChunkName: "component---src-pages-about-index-js" */),
  "component---src-pages-blog-[slug]-index-js": () => import("./../../../src/pages/blog/[slug]/index.js" /* webpackChunkName: "component---src-pages-blog-[slug]-index-js" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-contact-index-js": () => import("./../../../src/pages/contact/index.js" /* webpackChunkName: "component---src-pages-contact-index-js" */),
  "component---src-pages-gohighlevel-expert-index-js": () => import("./../../../src/pages/gohighlevel-expert/index.js" /* webpackChunkName: "component---src-pages-gohighlevel-expert-index-js" */),
  "component---src-pages-helloworld-index-js": () => import("./../../../src/pages/helloworld/index.js" /* webpackChunkName: "component---src-pages-helloworld-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mentorship-index-js": () => import("./../../../src/pages/mentorship/index.js" /* webpackChunkName: "component---src-pages-mentorship-index-js" */),
  "component---src-pages-mentorship-inquiry-index-js": () => import("./../../../src/pages/mentorship/inquiry/index.js" /* webpackChunkName: "component---src-pages-mentorship-inquiry-index-js" */),
  "component---src-pages-projects-index-js": () => import("./../../../src/pages/projects/index.js" /* webpackChunkName: "component---src-pages-projects-index-js" */),
  "component---src-pages-services-ai-solutions-index-js": () => import("./../../../src/pages/services/ai-solutions/index.js" /* webpackChunkName: "component---src-pages-services-ai-solutions-index-js" */),
  "component---src-pages-services-blockchain-solutions-index-js": () => import("./../../../src/pages/services/blockchain-solutions/index.js" /* webpackChunkName: "component---src-pages-services-blockchain-solutions-index-js" */),
  "component---src-pages-services-design-and-development-index-js": () => import("./../../../src/pages/services/design-and-development/index.js" /* webpackChunkName: "component---src-pages-services-design-and-development-index-js" */),
  "component---src-pages-services-mvp-development-index-js": () => import("./../../../src/pages/services/mvp-development/index.js" /* webpackChunkName: "component---src-pages-services-mvp-development-index-js" */),
  "component---src-pages-services-software-development-index-js": () => import("./../../../src/pages/services/software-development/index.js" /* webpackChunkName: "component---src-pages-services-software-development-index-js" */),
  "component---src-pages-webflow-developer-index-js": () => import("./../../../src/pages/webflow-developer/index.js" /* webpackChunkName: "component---src-pages-webflow-developer-index-js" */),
  "component---src-pages-wordpress-developer-index-js": () => import("./../../../src/pages/wordpress-developer/index.js" /* webpackChunkName: "component---src-pages-wordpress-developer-index-js" */),
  "component---src-pages-zapier-expert-index-js": () => import("./../../../src/pages/zapier-expert/index.js" /* webpackChunkName: "component---src-pages-zapier-expert-index-js" */)
}

